import React from 'react'
import AllTabData from './AllTabData'

const Occasion = () => {
  return (
    <div>
      <AllTabData filterType="occasion" />
      </div>
  )
}

export default Occasion
