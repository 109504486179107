import React from "react";
import Step from "../Components/Step";
import SearchForm from "../Components/SearchForm";
import { Button, Card, Col, Rate, Row } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import HotelList from "../Components/HotelList";
import Filter from "../Components/Filter";

const SelectHotel = () => {
  return (
    <div>
      <div className="container">
        <Step active={2} />
        <SearchForm />
        {/* <Filter /> */}
        <HotelList />
      
      </div>
    </div>
  );
};

export default SelectHotel;
