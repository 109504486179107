import React, { useEffect, useState } from "react";
import { Button, Row, Col, Dropdown, InputNumber } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Destination from "./Destination";
import SelectDate from "./SelectDate";
import RoomAndGuest from "./RoomAndGuest";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addAllData } from "../redux/hotelSlice";

const SearchForm = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { destination, date, guest } = useSelector((state) => state.cart);
  const checkIn = date.checkIn;
  const checkOut = date.checkOut;
  const dispatch = useDispatch();

  const fetchHotelsWithTypesAndRooms = async () => {
    try {
      // Fetch all data
      const hotelsResponse = await axios.get(
        `${API_BASE_URL}/hotels/getHotels.php`
      );
      const hotelTypesResponse = await axios.get(
        `${API_BASE_URL}/roomType/getRoomType.php`
      );
      const availableRoomsResponse = await axios.get(
        `${API_BASE_URL}/RAS/availibilityWithCheckInCheckOutDate.php`,
        { params: { checkIn, checkOut } }
      );

      // Extract data
      const hotels = hotelsResponse.data.data;
      const hotelTypes = hotelTypesResponse.data.data;
      const availableRooms = availableRoomsResponse.data.available_rooms;

      // Merge Data
      const hotelsWithDetails = hotels.map((hotel) => ({
        ...hotel,
        hotelType: hotelTypes
          .filter((type) => type.hotelId === hotel.id)
          .map((type) => ({
            ...type,
            availableRoom: availableRooms.filter(
              (room) => room.roomType_Id === type.id
            ),
          })),
      }));

      console.log("Merged Hotel Data:", hotelsWithDetails);
      dispatch(addAllData(hotelsWithDetails))
      return hotelsWithDetails;
    } catch (error) {
      console.error("Error fetching hotels with types and rooms:", error);
    }
  };

  useEffect(() => {
    if (
      destination.city &&
      destination.country &&
      date.checkIn &&
      date.checkOut &&
      guest.rooms
    ) {
      fetchHotelsWithTypesAndRooms();
    }
  },[destination, date, guest])

  const handleSearch = () => {
    if (
      destination.city &&
      destination.country &&
      date.checkIn &&
      date.checkOut &&
      guest.rooms
    ) {
      navigate("/hotels");
      fetchHotelsWithTypesAndRooms();
    } else {
      alert("Please fill in all fields before searching.");
    }
  };

  return (
    <div className="ptb-3 search_form">
      <Row
        justify="center"
        style={{
          padding: "20px",
          border: "1px solid #eaeaea",
          borderRadius: "8px",
          alignItems: "center",
        }}
      >
        <Col xs={24} sm={24} md={12} lg={7} xl={7}>
          <Destination />
        </Col>

        <Col xs={24} sm={24} md={12} lg={7} xl={7}>
          <SelectDate />
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <RoomAndGuest />
        </Col>
        <Col className="continue-btn" xs={24} sm={24} md={12} lg={4} xl={4}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            className="custom-button"
            onClick={handleSearch}
          >
            CONTINUE
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchForm;
