import React, { useEffect, useState } from "react";
import { Button, Card, Col, Rate, Row, Drawer, Modal } from "antd";
import { EnvironmentOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ShowRoom from "./ShowRoom";
import HotelDetails from "./HotelDetails";
import { useSelector } from "react-redux";

const HotelList = () => {
  const [roomCatagory, setRoomCatagory] = useState("");
  const [roomData, setRoomData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const [hotelData, setHotelData] = useState([]);

  const hotels = useSelector((state) => state.hotel.allData);
  const availableRoom = useSelector((state) => state.hotel.availableRoom);
  const destination = useSelector((state) => state.cart.destination);
  const guest = useSelector((state) => state.cart.guest);

  useEffect(() => {
    if (availableRoom.length > 0 && hotels.length > 0) {
      const hotelIds = [...new Set(availableRoom.map((room) => room.hotel_Id))];
      const filteredHotels = hotels.filter((hotel) =>
        hotelIds.includes(hotel.id)
      );
      const filterByCity = filteredHotels.filter((item) => {
        return (
          item.city?.trim().toLowerCase() ===
            destination.city?.trim().toLowerCase() &&
          item.country?.trim().toLowerCase() ===
            destination.country?.trim().toLowerCase()
        );
      });

      const filterByGuests = filterByCity.filter((hotel) =>
        hotel.hotelType.some((type) => parseInt(type.sleeps) >= guest.adults)
      );
  
      setHotelData(filterByGuests);
    }
  }, [availableRoom, hotels, destination, guest]);

  const toggleDrawer = (item, catagory) => {
    setRoomData(item);
    setRoomCatagory(catagory);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };


  // Sho Hotel Details
  const showModal = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const truncateText = (text, wordLimit) => {
    const words = text?.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const openGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank"); // Open in new tab
  };

  return (
    <div className="hotels_list">
      <p style={{ padding: "20px" }}>
        <b>Search</b> By :- <b>City</b> : {destination.city}, <b>Country</b> :{" "}
        {destination.country}, <b>Guest</b> : {guest.adults}
      </p>
      {hotelData.map((item, index) => (
        <>
          <Card key={item.id} className="hotel-card">
            <Row>
              {/* Image Section */}
              <Col xs={24} sm={24} md={9} lg={9} xl={10}>
                <div className="image-container">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/hotels/${item.images[0]}`}
                    alt="Hotel"
                    className="hotel-image"
                  />
                </div>
              </Col>

              {/* Details Section */}
              <Col
                xs={24}
                sm={24}
                md={15}
                lg={15}
                xl={14}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="hotel-content">
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={16} xl={15}>
                      <h2
                        style={{ cursor: "pointer" }}
                        onClick={() => showModal(item)}
                      >
                        {item.hotelname}
                      </h2>{" "}
                      {/* Pass item here */}
                      <div className="rating">
                        <Rate allowHalf defaultValue={4.5} value={4.5} />{" "}
                        <span className="review-text">(25 reviews)</span>
                        <span
                          style={{ cursor: "pointer" }}
                          className="distance"
                          onClick={() =>
                            openGoogleMaps(item.latitude, item.longitude)
                          }
                        >
                          <EnvironmentOutlined /> see location
                        </span>
                      </div>
                      <p className="hotel-description">
                        {truncateText(item.description, 12)}
                      </p>
                      <div className="room-tag">
                        <span className="availability-label">Sold Out</span>
                      </div>
                      <div className="price-discount">
                        <p>
                          Last Minute Deal Save up to <b> 10%</b>
                        </p>
                      </div>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={2}></Col>
                    <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                      <div className="price">
                        <span>From</span>
                        <span className="rate-price-per-night">
                          {(() => {
                            if (!item.hotelType || item.hotelType.length === 0)
                              return null;

                            const prices = item.hotelType.map((type) =>
                              parseFloat(type.price)
                            );
                            const minPrice = Math.min(...prices).toFixed(0);
                            const maxPrice = Math.max(...prices).toFixed(0);

                            return `$${minPrice} - $${maxPrice}`;
                          })()}
                        </span>
                        <span>USD / Night</span>
                        <p>
                          Excluding Taxes &<br></br> Fees
                        </p>
                      </div>
                    </Col>
                  </Row>

                  {hotelData?.length >= 2 ? (
                    <Row>
                      <Col xs={24} sm={10} md={15} lg={15} xl={15}>
                        <div className="buttons">
                          <Button
                            className={`action-btn btn-outline`}
                            onClick={() => toggleDrawer(item, "room")}
                          >
                            Show Rooms <PlusCircleOutlined />
                          </Button>
                          <Button
                            className={`action-btn btn-outline`}
                            onClick={() => toggleDrawer(item, "offer")}
                          >
                            Show Offers <PlusCircleOutlined />
                          </Button>
                        </div>
                      </Col>
                      <Col xs={1} sm={1} md={0} lg={2} xl={2}></Col>
                      <Col xs={24} sm={10} md={9} lg={7} xl={7}>
                        <div className="buttons">
                          <Button
                            className={`action-btn btn-primary`}
                            onClick={() =>
                              toggleDrawer(item, "upgrads")
                            }
                          >
                            Show Upgrades <PlusCircleOutlined />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Card>

          {hotelData?.length === 1 ? (
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="buttons"
                  style={{
                    display: "flex",
                    gap: "20px 40px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className={`action-btn btn-outline`}
                    onClick={() => toggleDrawer(item, "room")}
                    style={{ padding: "22px 40px" }}
                  >
                    Show Rooms <PlusCircleOutlined />
                  </Button>
                  <Button
                    className={`action-btn btn-outline`}
                    onClick={() => toggleDrawer(item, "offer")}
                    style={{ padding: "22px 40px" }}
                  >
                    Show Offers <PlusCircleOutlined />
                  </Button>
                  <Button
                    className={`action-btn btn-primary`}
                    onClick={() => toggleDrawer(item, "upgrads")}
                    style={{ padding: "22px 40px" }}
                  >
                    Show Upgrades <PlusCircleOutlined />
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      ))}

      {/* Drawer Component */}
      <Drawer
        title={
          roomCatagory === "room"
            ? "Available Rooms"
            : roomCatagory === "offer"
            ? "Available Offers"
            : "Available Upgrads"
        }
        placement="bottom"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
        height={"80%"}
      >
        <ShowRoom rooms={roomData} roomCatagory={roomCatagory} />
      </Drawer>

      <Modal
        title={selectedHotel ? selectedHotel.hotelname : "Hotel Details"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <button
            onClick={handleCancel}
            style={{
              padding: "8px 16px",
              background: "#1890ff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Close
          </button>
        }
        width={1000}
      >
        {selectedHotel && (
          <>
            <HotelDetails selectedHotel={selectedHotel} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default HotelList;
