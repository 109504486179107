import React from "react";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../redux/searchHotelSlice";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { addDate } from "../redux/cartSlice";

const { RangePicker } = DatePicker;

const SelectDate = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.cart.date);
  console.log(selectedDate)

  // Select Date
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      dispatch(
        addDate({
          checkIn: dates[0].format("YYYY-MM-DD"),
          checkOut: dates[1].format("YYYY-MM-DD"),
          nights: dates[1].diff(dates[0], "days"),
        })
      );
    } else {
      dispatch(addDate({ checkIn: null, checkOut: null, nights: 0 }));
    }
  };

  // Disabled Dates
  const isDisabled = (current) => {
    if (!current) return false;
    return (
      current.isBefore(moment().startOf("day"))
    );
  };

  return (
    <div className="form-group">
      <label htmlFor="">
        <FontAwesomeIcon className="icon" icon={faCalendarDays} /> {selectedDate.nights} Night
      </label>
      <RangePicker
        disabledDate={isDisabled}
        onChange={handleDateChange}
        value={
          selectedDate.checkIn && selectedDate.checkOut
            ? [moment(selectedDate.checkIn, "YYYY-MM-DD"), moment(selectedDate.checkOut, "YYYY-MM-DD")]
            : []
        }
      />
      
    </div>
  );
};

export default SelectDate;
