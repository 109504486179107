import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { addItemToCart } from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";
import axios from "axios";

const { Option } = Select;

const AllTabData = ({ filterType }) => {
  const [itemStates, setItemStates] = useState({});
  const dispatch = useDispatch();

  const selectedDate = useSelector((state) => state.cart.date);

  // Get Data
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/personolizaion/getData.php`
      );
      const filteredData = response.data.data.filter(
        (item) => item.type === filterType
      );
      setData(filteredData);

      console.log(filteredData);
    } catch (error) {
      console.error("Error fetching Data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const responsive = useMemo(
    () => ({
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1200 },
        items: data.length < 3 ? 2 : 3,
      },
      desktop: {
        breakpoint: { max: 1199, min: 992 },
        items: 2,
      },
      tablet: {
        breakpoint: { max: 991, min: 768 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
      },
    }),
    [data]
  );

  const addToCart = (item) => {
    const { date, time, occesion, special, paymentType } =
      itemStates[item.id] || {};
    console.log(special);

    if (date && time) {
      const occesionPrice = parseFloat(occesion?.price) || 0;
      const specialPrice = parseFloat(special?.price) || 0;
      const totalPrice = occesionPrice + specialPrice;

      dispatch(
        addItemToCart({
          id: item.id,
          hotelId: item.hotelId,
          email: item.email,
          type: filterType,
          title: item.title,
          date,
          time,
          occesion: occesion?.title || null,
          occesionPrice,
          special: special?.title || null,
          specialPrice,
          price: paymentType !== "Later" ? totalPrice : 0,
          laterPrice: paymentType === "Later" ? totalPrice : 0,
          paymentType: paymentType || "Pay Now",
        })
      );
    } else {
      alert("Please select all required options.");
    }
  };

  const handleStateChange = (id, field, value) => {
    setItemStates((prevState) => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        [field]: value,
      },
    }));
  };

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={false}
          customTransition="transform 0.7s ease-in-out"
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          itemClass="dining"
        >
          {data?.map((item) => (
            <div
              key={item.id}
              className="room-item"
              style={
                data.length >= 3 ? { padding: "0 7px" } : { padding: "0 20px" }
              }
            >
              <div className="room-image">
                <img
                  src={`${API_BASE_URL}/personolizaion/${item.image}`}
                  alt="Hotel"
                  className="room-img"
                />
              </div>
              <div className="room-details">
                <h3 className="room-quality">{item.title}</h3>
                <p className="room-description">{item.content}</p>
                <div className="select-date-time-option">
                  <div className="select-date">
                    <label>Select Date</label>
                    <DatePicker
                      disabledDate={(current) => {
                        const checkIn = moment(
                          selectedDate.checkIn,
                          "YYYY-MM-DD"
                        );
                        const checkOut = moment(
                          selectedDate.checkOut,
                          "YYYY-MM-DD"
                        );
                        return (
                          current && (current < checkIn || current > checkOut)
                        );
                      }}
                      onChange={(date, dateString) =>
                        handleStateChange(item.id, "date", dateString)
                      }
                    />
                  </div>
                  <div className="select-time">
                    <label>Select Time</label>
                    <Form.Item name="time-picker">
                      <TimePicker
                        onChange={(time, timeString) =>
                          handleStateChange(item.id, "time", timeString)
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <Select
                  placeholder="Select Option"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleStateChange(
                      item.id,
                      "occesion",
                      item.occesion.find((opt) => opt.title === value)
                    )
                  }
                  value={itemStates[item.id]?.occesion?.value || undefined}
                >
                  {item.occesion.map((option, index) => (
                    <Option key={index} value={option.title}>
                      {option.title}{" "}
                      {option.price == "0" ? "" : ` - $${option.price} `}
                    </Option>
                  ))}
                </Select>

                {item.special && item.special.length > 0 && (
                  <Select
                    placeholder="Select Special"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      handleStateChange(
                        item.id,
                        "special",
                        item.special.find((opt) => opt.title === value)
                      )
                    }
                    value={itemStates[item.id]?.special?.value || undefined}
                  >
                    {item.special.map((option, index) => (
                      <Option key={index} value={option.title}>
                        {option.title} - ${option.price}
                      </Option>
                    ))}
                  </Select>
                )}

                {item.paynow === "yes" && (
                  <div className="radio-btn">
                    <Radio.Group
                      onChange={(e) =>
                        handleStateChange(
                          item.id,
                          "paymentType",
                          e.target.value
                        )
                      }
                      value={itemStates[item.id]?.paymentType || "Pay Now"}
                    >
                      <Radio value="Pay Now">Pay Now</Radio>
                      <Radio value="Later">Later</Radio>
                    </Radio.Group>
                  </div>
                )}

                <div className="room-rate-footer">
                  <button
                    onClick={() => addToCart(item)}
                    className="btn-add-stay"
                  >
                    <span className="hide-text">Add to My Stay</span>{" "}
                    <PlusOutlined />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default AllTabData;
