import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import Dining from "./tabData/Dining";
import Wellness from "./tabData/Wellness";
import Transportation from "./tabData/Transportation";
import Occasion from "./tabData/Occasion";
import Experiences from "./tabData/Experiences";
import Perks from "./tabData/Perks";
import EgiftShop from "./tabData/EgiftShop";

const Tab = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [fetchData, setFetchData] = useState([]);
  const [filteredTabs, setFilteredTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("Dining");
  const [visibleTabs, setVisibleTabs] = useState([]);
  const [dropdownTabs, setDropdownTabs] = useState([]);

  // API call to fetch tabs
  useEffect(() => {
    const fetchTab = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/personolizaion/getExperiences_tab.php`, {
          params: { hotelId: "123" },
        });

        if (response.data.status === "success") {
          setFetchData(response.data.data);
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchTab();
  }, []);

  const allTabs = [
    { name: "Dining", key: "dining", icon: "images/tab-icon-01.png" },
    { name: "Wellness", key: "wellness", icon: "images/tab-icon-03.png" },
    { name: "Transportation", key: "transportation", icon: "images/tab-icon-02.png" },
    { name: "Occasion", key: "occasion", icon: "images/tab-icon-04.png" },
    { name: "Experiences", key: "experiences", icon: "images/tab-icon-08.png" },
    { name: "eGift Shop", key: "egiftshop", icon: "images/tab-icon-05.png" },
    { name: "Perks", key: "perks", icon: "images/tab-icon-06.png" },
  ];

  // Filter tabs when fetchData updates
  useEffect(() => {
    if (fetchData.length > 0) {
      const fetchTitles = fetchData.map(item => item.title.toLowerCase());
      const filtered = allTabs.filter(tab => fetchTitles.includes(tab.key));
      setFilteredTabs(filtered);
    }
  }, [fetchData]);

  // Update visible & dropdown tabs on screen resize or filteredTabs update
  useEffect(() => {
    const updateTabs = () => {
      const width = window.innerWidth;
      let visible = [];
      let dropdown = [];

      if (filteredTabs.length > 0) {
        if (width > 1150) {
          visible = filteredTabs.slice(0, 4);
          dropdown = filteredTabs.slice(4);
        } else if (width > 991) {
          visible = filteredTabs.slice(0, 3);
          dropdown = filteredTabs.slice(3);
        } else if (width > 790) {
          visible = filteredTabs.slice(0, 4);
          dropdown = filteredTabs.slice(4);
        } else if (width > 635) {
          visible = filteredTabs.slice(0, 3);
          dropdown = filteredTabs.slice(3);
        } else if (width > 440) {
          visible = filteredTabs.slice(0, 2);
          dropdown = filteredTabs.slice(2);
        } else {
          visible = filteredTabs.slice(0, 1);
          dropdown = filteredTabs.slice(1);
        }
      }

      setVisibleTabs(visible);
      setDropdownTabs(dropdown);
    };

    updateTabs();
    window.addEventListener("resize", updateTabs);
    return () => window.removeEventListener("resize", updateTabs);
  }, [filteredTabs]);

  return (
    <div className="personalization-tab">
      <ul className="personalization-tab-list">
        {visibleTabs.map((tab) => (
          <li
            key={tab.name}
            className={`personalization-tab-item ${activeTab === tab.name ? "active" : ""}`}
            onClick={() => setActiveTab(tab.name)}
          >
            <img src={`${process.env.PUBLIC_URL}/${tab.icon}`} alt="icon" />
            <span>{tab.name}</span>
          </li>
        ))}

        {dropdownTabs.length > 0 && (
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                {dropdownTabs.map((tab) => (
                  <Menu.Item
                    key={tab.name}
                    onClick={() => setActiveTab(tab.name)}
                    className={`personalization-tab-item ${activeTab === tab.name ? "active" : ""}`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${tab.icon}`}
                      alt={`${tab.name}-icon`}
                      style={{ marginRight: 8 }}
                    />
                    {tab.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <li className="personalization-tab-item more-tab">
              <span>More</span> <DownOutlined />
            </li>
          </Dropdown>
        )}
      </ul>

      <div className="tab-content">
        {activeTab === "Dining" && <Dining />} 
        {activeTab === "Wellness" && <Wellness />}
        {activeTab === "Transportation" && <Transportation />}
        {activeTab === "Occasion" && <Occasion />}
        {activeTab === "Experiences" && <Experiences />}
        {activeTab === "Perks" && <Perks />}
        {activeTab === "eGift Shop" && <EgiftShop />}
      </div>
      
    </div>
  );
};

export default Tab;
