import React from "react";
import AllTabData from "./AllTabData";

const Dining = () => {
  return (
    <div>
      <AllTabData filterType="dining" />
    </div>
  );
};

export default Dining;
