import React from "react";
import Step from "../Components/Step";
import SearchForm from "../Components/SearchForm";

const SelectHotelSingle = () => {
  return (
    <div>
      <div className="container">
        <Step active={2} step2heading="Select Rooms" />
        <SearchForm />
      
      </div>
    </div>
  );
};

export default SelectHotelSingle;
