import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  message,
  Form,
  InputNumber,
  Radio,
  Modal,
} from "antd";
import Cart2 from "./Cart2";
import { BsEmojiHeartEyesFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import axios from "axios";

const ConfirmForm = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCartData = useSelector((state) => state.cart);
  const getUserData = useSelector((state) => state.auth);
  console.log(getUserData);
  const [form] = Form.useForm();


  const handleBackToHome = () => {
    setIsModalOpen(false);
    window.location.href = "/";
  };

  const [selectRoomReqest, setSelectRoomReqest] = useState([]);

  const onChangeroomRequest = (checkedValues) => {
    setSelectRoomReqest(checkedValues);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const guestAddress = {
      address1: values.address1,
      address2: values?.address2 || '',
      city: values.city,
      state: values.state,
      country: values.country,
      zipcode: values.zipcode,
    };
    const paymentData = {
      creditCardName: values.creditCardName,
      creditCardNumber: values.creditCardNumber,
      expirationMonth: values.expirationMonth,
      expirationYear: values.expirationYear,
      BillingZipCode: values.BillingZipCode,
      CVVNumber: values.CVVNumber,
    };

    console.log(getCartData?.date?.checkIn)
    
    const formData = new FormData();
    formData.append("userId", getUserData?.userId || "");  
    formData.append("fName", values.fName);
    formData.append("lName", values.lName);
    formData.append("email", values.email);
    formData.append("mobileNumber", values.mobileNumber);
    formData.append("guestAddress", JSON.stringify(guestAddress));
    formData.append("roomrequest", JSON.stringify(values?.roomrequest || []));
    formData.append("other_request", values?.other_request || "No Other Request");
    formData.append("extraFries", values.extraFries ? "Yes" : "No");
    formData.append("acceptTermCondition", values.acceptTermCondition ? "true" : "false");
    formData.append("totalPrice", getCartData?.allPrice?.Total || 0);
    formData.append("checkIn", getCartData?.date?.checkIn || null);
    formData.append("checkOut", getCartData?.date?.checkOut || null);
    formData.append("nights", getCartData?.date?.nights || 0);
    formData.append("asign_roomId", getCartData?.asignRoom?.id || "");
    formData.append("asign_roomNumber", getCartData?.asignRoom?.roomNumber || "");
    formData.append("destination", JSON.stringify(getCartData?.destination || {}));
    formData.append("guest", JSON.stringify(getCartData?.guest || {}));
    formData.append("hotel", JSON.stringify(getCartData?.hotel || {}));
    formData.append("items", JSON.stringify(getCartData?.items || []));
    formData.append("promo", JSON.stringify(getCartData?.promo || {}));
    formData.append("roomType", JSON.stringify(getCartData?.roomType || {}));
    formData.append("allPrice", JSON.stringify(getCartData?.allPrice || {}));
    formData.append("paymentData", JSON.stringify(paymentData || {}));


    try {
      const response = await axios.post(
        `${API_BASE_URL}/submitBooking.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);

      if (response.data.status === "success") {
        message.success(response.data.message);        
        // form.resetFields();
        // setIsModalOpen(true);
      }else{
        message.error("Form Not Submited Successfully");        
      }
    } catch (error) {
      message.error("Failed to submit data");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }

  };

  return (
    <div className="confirmation-form">
      <Modal
        title="Booking Successful"
        open={isModalOpen}
        footer={[
          <Button key="home" type="primary" onClick={handleBackToHome}>
            Back to Home
          </Button>,
        ]}
        closable={false}
        centered
      >
        <p>Your booking has been successfully completed!</p>
      </Modal>

      {loading && <Loader />}

      <div className="back_btn">
        <Link to={"/personalization"}>
          <button>
            <FontAwesomeIcon icon={faLeftLong} />
          </button>
          Back
        </Link>
      </div>

      <Row
        gutter={24}
        className="confirmation-row"
        style={{ flexWrap: "wrap-reverse", gap: "30px 0" }}
      >
        <Col xs={24} sm={24} md={24} lg={15} xl={15}>
          <Form
            form={form}
            name="confirmation_form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="First Name"
                  name="fName"
                  rules={[
                    { required: true, message: "Please enter your First Name" },
                    { min: 3, message: "Name must be at least 3 characters" },
                    {
                      pattern: /^[a-zA-Z\s-]+$/,
                      message: "Name can only contain alphabets",
                    },
                  ]}
                >
                  <Input placeholder="Enter your first name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Last Name"
                  name="lName"
                  rules={[
                    { required: true, message: "Please enter your last name" },
                    { min: 3, message: "Name must be at least 3 characters" },
                    {
                      pattern: /^[a-zA-Z\s-]+$/,
                      message: "Name can only contain alphabets",
                    },
                  ]}
                >
                  <Input placeholder="Enter your last name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your email" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Mobile Number"
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Mobile Number",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Mobile Number must be exactly 10 digits",
                    },
                  ]}
                >
                  <InputNumber placeholder="Enter your Mobile Number" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <hr />
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Address Line 1"
                  name="address1"
                  rules={[
                    { required: true, message: "Please enter your Address" },
                  ]}
                >
                  <Input placeholder="Enter your Address" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label="Address Line 2" name="address2">
                  <Input placeholder="Enter your Address" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    { required: true, message: "Please enter your City" },
                  ]}
                >
                  <Input placeholder="Enter your City" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="State/Provine"
                  name="state"
                  rules={[
                    { required: true, message: "Please enter your State" },
                  ]}
                >
                  <Input placeholder="Enter your State" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Country/Region"
                  name="country"
                  rules={[
                    { required: true, message: "Please enter your Country" },
                  ]}
                >
                  <Input placeholder="Enter your Country" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Zip Code / Postal Code"
                  name="zipcode"
                  rules={[
                    { required: true, message: "Please enter your Zip Code" },
                    {
                      pattern: /^[0-9]{5,6}(-[0-9]{4})?$/,
                      message: "Invalid Zip Code format",
                    },
                  ]}
                >
                  <Input placeholder="Enter your Zip Code" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <hr />
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Room Request and Preferences"
                  name="roomrequest"
                  className="roomrequest"
                >
                  <Checkbox.Group
                    value={selectRoomReqest}
                    onChange={onChangeroomRequest}
                  >
                    <Checkbox value="Early Check in">Early Check in</Checkbox>
                    <Checkbox value="Late Check out">Late Check out</Checkbox>
                    <Checkbox value="In-Room services">
                      In-Room services
                    </Checkbox>
                    <Checkbox value="Extra towel, pillow, blanket">
                      Extra towel, pillow, blanket
                    </Checkbox>
                    <Checkbox value="Baby Crib or Antilop High Chair">
                      Baby Crib or Antilop High Chair
                    </Checkbox>
                    <Checkbox value="Laundry services">
                      Laundry services
                    </Checkbox>
                    <Checkbox value="Valet services">Valet services</Checkbox>
                    <Checkbox
                      value="Room Temperature Cold"
                      disabled={selectRoomReqest.includes(
                        "Room Temperature Warm"
                      )}
                    >
                      Room Temperature Cold
                    </Checkbox>
                    <Checkbox
                      value="Room Temperature Warm"
                      disabled={selectRoomReqest.includes(
                        "Room Temperature Cold"
                      )}
                    >
                      Room Temperature Warm
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Any Special Request" name="other_request">
                  <TextArea placeholder="Any Special Request" />
                </Form.Item>
              </Col>

              <Form.Item name="extraFries" valuePropName="checked">
                <Checkbox>
                  Want Extra Fries with that? <BsEmojiHeartEyesFill />
                </Checkbox>
              </Form.Item>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <hr />
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label="Cardholder Name" name="creditCardName">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Credit/Debit Card Number"
                  name="creditCardNumber"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your card number",
                    },
                    {
                      pattern: /^[0-9]{16}$/,
                      message: "Card number must be 16 digits",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label="Expiration Month" name="expirationMonth">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label="Expiration Year" name="expirationYear">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="CVV Number"
                  name="CVVNumber"
                  rules={[
                    { required: false, message: "Please enter CVV" },
                    {
                      pattern: /^[0-9]{3,4}$/,
                      message: "CVV must be 3 or 4 digits",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label="Billing Zip Code" name="BillingZipCode">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <hr />
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>
                  All guest rooms and amenities at this hotel offer
                  complementary wireless internet access. We encourage you to
                  check in via the mobile app or the email link sent prior to
                  your arrival for a smooth check-in experience.
                </p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="acceptTermCondition"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "You must accept the terms and conditions"
                              )
                            ),
                    },
                  ]}
                >
                  <Checkbox>
                    I have read all terms, rate details, and accept the
                    cancellation policy.
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item wrapperCol={{}}>
                  <Button
                    className="btn-book-stay"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    BOOK MY STAY
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={9}
          xl={9}
          style={{ marginTop: "20px" }}
        >
          <Cart2 />
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmForm;
