import { Button, Col, Form, Input, message, Modal, Row, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Bookings = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [findModalOpen, setFindModalOpen] = useState(false);
  const [findLoading, setFindLoading] = useState(false);
  const authUserId = useSelector((state) => state.auth.userId);

  const getBookings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/getUserBooking.php`, {
        params: { userId: authUserId },
      });
      const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setBookings(sortedData);
    } catch (error) {
      console.error("Error fetching Hotels:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBookings();
  }, []);


  // Table Column
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onCell: () => ({ style: { minWidth: 100 } }),
    },
    {
      title: "Hotel Name",
      dataIndex: "hotel",
      key: "hotel.hotelname",
      onCell: () => ({ style: { minWidth: 100 } }),
      render: (hotel) => hotel?.hotelname || "N/A",
    },
    {
      title: "Room Name",
      dataIndex: "roomType",
      key: "roomType.roomName",
      onCell: () => ({ style: { minWidth: 100 } }),
      render: (roomType) => roomType?.roomName || "N/A",
    },
    {
      title: "Room Number",
      dataIndex: "asign_roomNumber",
      key: "asign_roomNumber",
      onCell: () => ({ style: { minWidth: 100 } }),
    },
    {
      title: "checkIn",
      dataIndex: "checkIn",
      key: "checkIn",
      onCell: () => ({ style: { minWidth: 100 } }),
    },
    {
      title: "checkOut",
      dataIndex: "checkOut",
      key: "checkOut",
      onCell: () => ({ style: { minWidth: 100 } }),
    },
    {
      title: "Booking Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      onCell: () => ({ style: { minWidth: 100 } }),
      render: (status) => {
        let color = "";
        switch (status) {
          case "pending":
            color = "yellow";
            break;
          case "confirmed":
            color = "green";
            break;
          case "cancelled":
            color = "red";
            break;
          case "rejected":
            color = "volcano";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "CheckIn Status",
      dataIndex: "checkInStatus",
      key: "checkInStatus",
      onCell: () => ({ style: { minWidth: 100 } }),
      render: (status, record) => {
        if (status === "pending" && record.checkIn) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const checkInDate = new Date(record.checkIn);
          checkInDate.setHours(0, 0, 0, 0);

          const timeDiff = checkInDate - today;
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

          if (daysLeft > 1) {
            return <Tag color="blue">{`${daysLeft} days left`}</Tag>;
          } else if (daysLeft === 1) {
            return <Tag color="blue">1 day left</Tag>;
          } else if (daysLeft === 0) {
            return <Tag color="blue">Today</Tag>;
          } else {
            return <Tag color="red">No checkIn</Tag>;
          }
        }
        let color = "";
        switch (status) {
          case "checkin":
            color = "orange";
            break;
          case "checkout":
            color = "green";
            break;
          default:
            color = "default";
        }

        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      onCell: () => ({ style: { minWidth: 100 } }),
      render: (status) => {
        let color = "";
        switch (status) {
          case "pending":
            color = "yellow";
            break;
          case "paid":
            color = "green";
            break;
          case "failed":
          case "cancelled":
            color = "red";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  const isMobile = useSelector((state) => state.mobile.isMobile);
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/bookings/${id}`);
  };

  const isOpenFindModal = () => {
    setFindModalOpen(true);
  };
  const isCloseFindModal = () => {
    setFindModalOpen(false);
  };
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setFindLoading(true)
    try {
      const response = await axios.get(`${API_BASE_URL}/getSingleBooking.php`, {
        params: { id: values.confirmation },
      });
      console.log(response.data.data);
      if (response.data.status === "success") {
        handleRowClick(values.confirmation);
      } else {
        message.error("Booking Not Found");
      }
    } catch (error) {
      console.error("Error fetching Hotels:", error);
    } finally {
      setFindLoading(false)
    }
  };

  return (
    <div>
      <div className="container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px 0",
          }}
        >
          <h2>Your Bookings</h2>
          <Button onClick={isOpenFindModal} className="btn btn-primary">
            Find Booking
          </Button>
        </div>

        <Modal
          open={findModalOpen}
          title={"Find Booking"}
          onCancel={isCloseFindModal}
          footer={false}
        >
          <Form
            form={form}
            name="Find Booking Form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Confirmation Numbar"
                  name="confirmation"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Confirmation Numbar",
                    },
                  ]}
                >
                  <Input placeholder="confirmation numbar" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item wrapperCol={{}}>
                  <Button
                    className="btn-find-hotel"
                    type="primary"
                    htmlType="submit"
                    loading={findLoading}
                  >
                    Find Booking
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Table
          dataSource={bookings}
          columns={columns}
          rowKey="id"
          loading={loading}
          scroll={{ x: 800 }}
          size={isMobile ? "small" : "default"}
          pagination={{
            pageSize: 20,
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record.id),
          })}
        />
      </div>
    </div>
  );
};

export default Bookings;
