import { createSlice } from '@reduxjs/toolkit';

const hotelSlice = createSlice({
    name: 'hotel',
    initialState: {
        hotels: [],
        hotelType: [],
        availableRoom: [],
        allData: [],
    },
    reducers: {
        addHotels: (state, action) => { state.hotels = action.payload },
        addHotelType : (state, action) => { state.hotelType = action.payload },
        addAvailableRoom : (state, action) => { state.availableRoom = action.payload },
        addAllData : (state, action) => { state.allData = action.payload },
    },
});

export const { addHotels, addHotelType, addAvailableRoom, addAllData } = hotelSlice.actions;
export default hotelSlice.reducer;
