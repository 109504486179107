import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button, Col, Rate, Row } from "antd";
import {
  EnvironmentOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";

const HotelDetails = ({ selectedHotel }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const openGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank"); // Open in new tab
  };

  return (
    <div className="hotel-details">
      <div className="images-gallery">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          itemClass="show-offer"
        >
          {selectedHotel?.images?.map((path, index) => (
            <div key={index}>
              <img
                src={`${process.env.PUBLIC_URL}/${path}`}
                alt="Hotel"
                width="100%"
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="hotel-details-info">
        <div
          style={{ cursor: "pointer" }}
          className="location"
          onClick={() =>
            openGoogleMaps(selectedHotel?.latitude, selectedHotel?.longitude)
          }
        >
          <EnvironmentOutlined /> See Google Map Location
        </div>
        <div className="rating">
          <Rate allowHalf defaultValue={4.5} />{" "}
          <span className="review-text">(273 reviews)</span>
        </div>
      </div>
      <Button className="btn-primary" type="primary">
        Select this Hotel
      </Button>
      <div className="hotel-overview">
        <h3>Overview</h3>
        <p>{selectedHotel?.description}</p>
      </div>
      <div className="hotel-contact-info">
        <Row>
          <Col xs={24} sm={24} md={10} lg={9} xl={9}>
            <h4>Location</h4>
            <div className="address">
              <span>
                {selectedHotel?.address}, {selectedHotel?.city},{" "}
                {selectedHotel?.country}, {selectedHotel?.zipCode}
              </span>
              <span>Telephone: {selectedHotel?.phone}</span>
              <span>Email : {selectedHotel?.email}</span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={15} xl={15}>
            <div className="map"></div>
          </Col>
        </Row>
      </div>

      <div className="room-amenities">
        <h3 className="room-detail-header">Amenities</h3>
        <div className="amenitiesContainer">
          {selectedHotel?.amenities?.map((item, index) => (
            <div key={index} className="room-amenity">
              <CheckOutlined />{" "}
              <span className="room-amenity-name">
                {item}{" "}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HotelDetails;
