import { Button } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  applyPromoCode,
  clearPromoCode,
  removeItemFromCart,
} from "../redux/cartSlice";
import { CloseCircleFilled } from "@ant-design/icons";

const Cart = () => {
  const getCartData = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const removeItem = (id) => {
    dispatch(removeItemFromCart(id));
  };

  // Promo code
  const [promoCode, setPromoCode] = useState("");

  const handleApplyPromo = () => {
    dispatch(applyPromoCode(promoCode));
  };

  const removePromocode = () => {
    dispatch(clearPromoCode());
  };

  return (
    <div className="hotel-cart">
      <div className="cart-top">
        <h3>Your Cart</h3>
      </div>
      {getCartData && (
        <>
          <div className="cart-middle">
            <div
              className="cart-itme"
              style={{ display: "flex", width: "100%", padding: "10px" }}
            >
              <div className="left" style={{ width: "80%" }}>
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {getCartData?.roomType?.roomName}
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "normal",
                    paddingTop: "10px",
                  }}
                >
                  {getCartData?.hotel?.hotelname}
                </div>
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  ${getCartData?.roomType?.price} / night
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    paddingTop: "10px",
                  }}
                >
                  {getCartData?.date?.checkIn} - {getCartData?.date?.checkOut}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    paddingTop: "10px",
                  }}
                >
                  {getCartData?.guest?.adults} Guests |{" "}
                  {getCartData?.date?.nights} Nights
                </div>
              </div>
              <div
                className="right"
                style={{
                  width: "20%",
                  textAlign: "end",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                ${getCartData?.allPrice?.roomTotal}
              </div>
            </div>

            {getCartData?.items?.map((item, index) => (
              <div
                className="cart-itme"
                style={{ display: "flex", width: "100%", padding: "10px" }}
              >
                <div className="left" style={{ width: "80%" }}>
                  <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {item.title}
                  </div>

                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "normal",
                      paddingTop: "10px",
                    }}
                  >
                    {item.occesion}
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "normal",
                      paddingTop: "10px",
                    }}
                  >
                    {item.special}
                  </div>
                  {item.laterPrice !== 0 && (
                    <div style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Pay Later : ${item.laterPrice}
                    </div>
                  )}
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      paddingTop: "10px",
                    }}
                  >
                    {item.date} - {item.time}
                  </div>
                </div>
                <div
                  className="right"
                  style={{
                    width: "20%",
                    textAlign: "end",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {item.price === 0 ? "" : `$${item.price}`}

                  <Button
                    type="light"
                    onClick={() => removeItem(item.id)}
                    className="cart-remove"
                  >
                    X Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="cart-middle">
            <ul className="cart-total-price">
              <li>
                <span className="name">Subtotal</span>
                <span className="item-price">
                  ${getCartData?.allPrice?.subTotal}
                </span>
              </li>
              <li>
                <span className="name">Taxes</span>
                <span className="item-price">
                  ${getCartData?.allPrice?.taxes}
                </span>
              </li>
              <li>
                <span className="name">Discount</span>
                <span className="item-price">
                  ${getCartData?.allPrice?.discount}
                </span>
              </li>
              {getCartData?.promo?.code && (
                <li>
                  <span className="name">
                    promocode discount (
                    {getCartData?.promo?.type === "fixed" && "$"}
                    {getCartData?.promo?.value}
                    {getCartData?.promo?.type === "percentage" && "%"})
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={removePromocode}
                    >
                      {" "}
                      <CloseCircleFilled />{" "}
                    </span>
                  </span>
                  <span className="item-price">
                    ${getCartData?.promo?.price}
                  </span>
                </li>
              )}
              <li>
                <span className="name">Total Due </span>
                <span className="item-price">
                  ${getCartData?.allPrice?.Total}
                </span>
              </li>
              {getCartData?.allPrice?.laterPayment !== 0 && (
                <li>
                  <span className="name">Pay Later </span>
                  <span className="item-price">
                    ${getCartData?.allPrice?.laterPayment}
                  </span>
                </li>
              )}
            </ul>
          </div>
          <div className="cart-middle">
            <div className="promo-code">
              <label>Have a gift certificate or promo code?</label>
              <Input
                placeholder="Enter Your Promo Code"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <Button className="apply-btn" onClick={handleApplyPromo}>
                Apply
              </Button>
              <p style={{ color: "red" }}>{getCartData?.promo?.error}</p>
            </div>
          </div>
          <div className="cart-bottom">
            <div className="cart-total-cost">
              <label>TOTAL COST</label>
              <span>${getCartData?.allPrice?.Total}.00</span>
            </div>
            <div className="cart-button">
              <Link to={"/confirmation"}>
                <Button className="catr-btn">Continue</Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
