import React from "react";
import AllTabData from "./AllTabData";

const Transportation = () => {
  return (
    <div>
      <AllTabData filterType="transportation" />
    </div>
  );
};

export default Transportation;
