import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Drawer, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/authSlice";

const AppHeader = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const isAuthenticate = useSelector((state) => state.auth.isAuthenticate);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const navigate = useNavigate();

  const CloseSidebar = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    CloseSidebar();
  }, [navigate]);

  const menuItems = [
    {
      key: "1",
      label: "Home",
      children: [
        { key: "11", label: <Link to="/singleHotel/search">Single Hotel</Link> },
        { key: "12", label: <Link to="/">Multiple Hotels</Link> },
      ],
    },
    {
      key: "3",
      label: "Experiences",
      children: [
        { key: "31", label: <Link to="/personalization">With Tab</Link> },
        { key: "32", label: <Link to="/withoutTab/personalization">Without Tab</Link> },
      ],
    },
    { key: "4", label: "About" },
    { key: "5", label: "Services" },
    { key: "6", label: "Offers" },
    isAuthenticate
      ? {
          key: "7",
          label: "Profile",
          children: [
            {
              key: "71",
              label: <Link to="/profile">profile</Link>,
            },
            {
              key: "73",
              label: <Link to="/bookings">Bookings</Link>,
            },
            {
              key: "72",
              label: <Link onClick={handleLogout}>Logout</Link>,
            },
          ],
        }
      : {
          key: "8",
          label: "Signin or Join",
          children: [
            { key: "81", label: <Link to="/login">Sign in</Link> },
            { key: "82", label: <Link to="/register">Sign Up</Link> },
          ],
        },
  ];

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <Col>
          <div className="logo">
            <Link to="/">
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
            </Link>
          </div>
        </Col>
        <Col flex="auto" className="desktop-menu navbar-menu">
          <Menu mode="horizontal" defaultSelectedKeys={["1"]} items={menuItems} />
        </Col>

        <Col className="mobile-menu">
          <Button type="text" icon={<FontAwesomeIcon icon={faBars} />} onClick={toggleDrawer} />
        </Col>
      </Row>

      <Drawer title="Menu" placement="right" onClose={toggleDrawer} open={drawerVisible} className="mobile-sidebar-menu">
        <Menu mode="inline" defaultSelectedKeys={["1"]} items={menuItems} />
      </Drawer>
    </div>
  );
};

export default AppHeader;
