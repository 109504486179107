import React from 'react'
import AllTabData from './AllTabData'

const Wellness = () => {
  return (
    <div>
      <AllTabData filterType="wellness" />
    </div>
  )
}

export default Wellness
