import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    destination: { city: null, country: null },
    date: { checkIn: null, checkOut: null, nights: 0 },
    guest: { rooms: 1, adults: 1, children: 0 },
    hotel: {},
    roomType: {},
    asignRoom: {},
    items: [],
    promo: {
        code: null, type: '', value: 0, error: "", price: 0
    },
    allPrice: {
        roomTotal: 0,
        itemTotal: 0,
        laterPayment : 0,
        taxes: 10,
        discount: 5,
        subTotal: 0,
        Total: 0
    }

};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {

        addDestination: (state, action) => {
            state.destination = action.payload;
        },
        addDate: (state, action) => {
            state.date = action.payload;
        },
        addGuest: (state, action) => {
            state.guest = action.payload;
        },
        addHotel: (state, action) => {
            state.hotel = action.payload;
        },
        addRoomType: (state, action) => {
            state.roomType = action.payload;
        },
        addAsignRoom: (state, action) => {
            state.asignRoom = action.payload;
        },
        addItemToCart: (state, action) => {
            state.items.push(action.payload);
        },
        removeItemFromCart: (state, action) => {
            const id = action.payload;
            const index = state.items.findIndex((item) => item.id === id);
            if (index !== -1) {
                state.items.splice(index, 1);
            }
        },
        clearCart: (state) => {
            state.items = [];
        },


        // Up New

        applyPromoCode: (state, action) => {
            const promoCode = action.payload;
            const promoCodes = {
                PROMO01: { type: "percentage", code: "PROMO01", value: 10 },
                PROMO02: { type: "percentage", code: "PROMO02", value: 5 },
                PROMO03: { type: "fixed", code: "PROMO03", value: 5 },
            };

            if (state.promo.code === promoCode) {
                state.promo.error = "Promocode already applied.";
            } else {
                state.promo.error = "Only 1 promocode applied.";
            }

            if (promoCodes[promoCode]) {
                const promo = promoCodes[promoCode];
                state.promo.code = promoCode;
                state.promo.error = "";
                state.promo.type = promo.type;
                state.promo.value = promo.value;

                if (promo.type === "percentage") {
                    state.promo.price = (state.allPrice.subTotal * promo.value) / 100;
                } else if (promo.type === "fixed") {
                    state.promo.price = promo.value;
                }

            } else {
                state.promo.error = "Invalid promocode.";
            }
        },
        clearPromoError: (state) => {
            state.promo.error = "";
        },
        clearPromoCode: (state) => {
            state.promo.code = null;
            state.promo.price = 0;
            state.promo.error = "";
        },
        calculatePrice: (state) => {
            state.allPrice.roomTotal = state.roomType.price
                ? state.roomType.price * state.date.nights
                : 0;

            // Items ka total calculate karna
            state.allPrice.itemTotal = state.items.reduce((total, item) => total + item.price, 0);

            state.allPrice.laterPayment = state.items.reduce((total, item) => total + item.laterPrice, 0);

            // Subtotal calculate karna
            state.allPrice.subTotal = state.allPrice.roomTotal + state.allPrice.itemTotal;

            // Final Total calculate karna
            state.allPrice.Total =
                state.allPrice.subTotal + state.allPrice.taxes - state.allPrice.discount - state.promo.price;
        },
    },
});

export const { addDestination, addDate, addGuest, addHotel, addRoomType, addAsignRoom, addItemToCart, removeItemFromCart, clearCart, applyPromoCode, clearPromoError, clearPromoCode, calculatePrice } =
    cartSlice.actions;

export default cartSlice.reducer;
