import React from "react";
import AllTabData from "./AllTabData";

const Experiences = () => {
  return (
    <div>
      <AllTabData filterType="experiences" />
    </div>
  );
};

export default Experiences;
