import React from "react";
import AllTabData from "./AllTabData";

const EgiftShop = () => {
  return (
    <div>
      <AllTabData filterType="egiftshop" />
    </div>
  );
};

export default EgiftShop;
