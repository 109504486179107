import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button, Col, Rate, Row } from "antd";
import {
  EnvironmentOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";

const RoomDetails = ({selectedRoom}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="hotel-room-details">
      <Row gutter={16} className="hotel-room-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h3 className="room-details-header">
            {selectedRoom?.roomName}
          </h3>
          <div className="room-area-info">
            <span>Sleep {selectedRoom?.sleeps}</span> | <span>{selectedRoom?.bed} King bed</span> | <span>{selectedRoom?.squareFeet} Square Feet</span>
          </div>
          <ul className="hotel-room-amenities">
            {selectedRoom?.amenities?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="room-images-gallery">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              itemClass="show-offer"
            >
              {selectedRoom?.images?.map((img, index) => (
                <div key={index}>
                <img src={`${process.env.REACT_APP_API_URL}/roomType/${img}`} alt="Hotel" width="100%" />
              </div>
              ))}
            </Carousel>
          </div>
        </Col>
      </Row>
      <Row gutter={16} className="hotel-room-content">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p>
            {selectedRoom?.description}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default RoomDetails;
