import React from 'react'
import AllTabData from '../Components/tabData/AllTabData'

const TabWithoutTab = () => {
  return (
    <div>
      <AllTabData filterType="withoutTab" />
    </div>
  )
}

export default TabWithoutTab
