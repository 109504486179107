import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import RoomDetails from "./RoomDetails";
import { Button, Modal } from "antd";
import {
  addAsignRoom,
  addHotel,
  addRoomType,
  clearCart,
  clearPromoCode,
} from "../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";

const ShowRoom = ({ rooms, roomCatagory }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoom, setselectedRoom] = useState(null);

  const showModal = (hotel) => {
    setselectedRoom(hotel);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };

  const filteredRooms = rooms?.hotelType?.filter(
    (room) => room.catagory === roomCatagory && room?.availableRoom?.length > 0
  );

  // Add Item In Cart
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);

  const addToCart = (item) => {
    console.log(rooms);
    dispatch(
      addHotel({
        id: rooms.id,
        hotelname: rooms.hotelname,
        email: rooms.email,
        address: rooms.address,
        city: rooms.city,
        country: rooms.country,
        zipCode: rooms.zipCode,
        latitude: rooms.latitude,
        longitude: rooms.longitude,
      })
    );

    console.log(item);
    dispatch(
      addRoomType({
        id: item.id,
        hotelId: item.hotelId,
        catagory: item.catagory,
        roomName: item.roomName,
        sleeps: item.sleeps,
        price: parseInt(item.price),
      })
    );

    const asignRoom = item.availableRoom[0];
    console.log(asignRoom);
    dispatch(
      addAsignRoom({
        id: asignRoom.id,
        hotelId: asignRoom.hotel_Id,
        roomTypeId: asignRoom.roomType_Id,
        roomNumber: asignRoom.room_number,
      })
    );
    dispatch(clearCart());
    dispatch(clearPromoCode());
    navigate("/personalization");
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  return (
    <>
      <p style={{ marginBottom: "20px" }}>
        Search By <b>:-</b> CheckIn : <b>{cart?.date?.checkIn}</b>, CheckOut :{" "}
        <b>{cart?.date?.checkOut}</b>, Guest : <b>{cart?.guest?.adults}</b>
      </p>
      {filteredRooms?.length === 0 && (
        <div className="no-data-message">
          <h2>Any Room Not Available</h2>
        </div>
      )}
      {!filteredRooms ? (
        <p>Loading...</p>
      ) : (
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          itemClass="show-room"
        >
          {filteredRooms?.map((item, index) => (
            <div
              key={index}
              className="room-item"
              style={{ padding: "0 15px" }}
            >
              <div className="room-image">
                <img
                  src={`${process.env.REACT_APP_API_URL}/roomType/${item.images[0]}`}
                  alt="Hotel"
                  className="room-img"
                />
              </div>
              <div className="room-details">
                <h3
                  style={{ cursor: "pointer" }}
                  className="room-quality"
                  onClick={() => showModal(item)}
                >
                  {item.roomName}
                </h3>
                <p className="room-description">
                  {truncateText(item.description, 18)}
                </p>
                <span className="room-rate">
                  {item?.availableRoom?.length} Room Left
                </span>
                <div className="room-rate-footer">
                  <span className="room-price">${item.price}</span>
                  <button
                    onClick={() => addToCart(item)}
                    className="btn-select"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}

      <Modal
        title={selectedRoom ? selectedRoom.roomname : "Room Details"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="close" type="primary" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {selectedRoom && (
          <>
            <RoomDetails selectedRoom={selectedRoom} />
          </>
        )}
      </Modal>
    </>
  );
};

export default ShowRoom;
