import { Layout } from 'antd';
import './App.css';
import "react-lazy-load-image-component/src/effects/blur.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from './Components/PrivateRoute';
import AppHeader from './Components/AppHeader';
import AppFooter from './Components/AppFooter';
import SelectDate from './pages/SelectDate';
import SelectHotel from './pages/SelectHotel';
import SelectPersonolization from './pages/SelectPersonolization';
import Confirmation from './pages/Confirmation';
import Login from './auth/Login';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import { addAvailableRoom, addHotels, addHotelType } from './redux/hotelSlice';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { logout } from './redux/authSlice';
import ScrollToTop from './Components/ScrollToTop';
import SelectDateSingle from './singleHotel/SelectDateSingle';
import SelectHotelSingle from './singleHotel/SelectHotelSingle';
import SelectPersonolizationWithoutTab from './singleHotel/SelectPersonolizationWithoutTab';
import { calculatePrice } from './redux/cartSlice';
import Profile from './auth/Profile';
import Bookings from './pages/Bookings';
import BookingDetails from './pages/BookingDetails';
import Loader from './Components/Loader';

const { Header, Content, Footer } = Layout;

function App() {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const { roomType, date, items, promo } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(true); // State for Loader


  useEffect(() => {
    dispatch(calculatePrice())
  }, [roomType, date, items, promo])

  const fetchHotels = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/hotels/getHotels.php`);
      const hotelData = response.data.data;
      dispatch(addHotels(hotelData));
      console.log(response);

    } catch (error) {
      console.error("Error fetching Hotels:", error);
    } finally {
    }
  };

  const fetchHotelType = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/roomType/getRoomType.php`);
      const hotelTypeData = response.data.data;
      dispatch(addHotelType(hotelTypeData));

    } catch (error) {
      console.error("Error fetching Hotel Type:", error);
    } finally {
    }
  };

  const checkIn = date.checkIn;
  const checkOut = date.checkOut;

  const fetchAvailableRooms = async () => {
    try {

      const response = await axios.get(
        `${API_BASE_URL}/RAS/availibilityWithCheckInCheckOutDate.php`,
        {
          params: { checkIn, checkOut },
        }
      );

      dispatch(addAvailableRoom(response.data.available_rooms));

    } catch (error) {
      console.error("Error fetching available rooms:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchHotels(), fetchHotelType()]); // Wait for all API calls
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (date.checkIn !== null && date.checkOut !== null) {
      fetchAvailableRooms();
    }
  }, [date]);







  // Call In Page Reload to Login 
  const userId = useSelector((state) => state.auth.userId);

  const callOnReload = () => {
    if (userId === null) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    window.onload = callOnReload;
  }, []);

  const basename = process.env.REACT_APP_BASENAME || "/";

  return (
    <div className="App">
      <Router basename={basename}>
        <ScrollToTop />
        {loading ? (
          <Loader />
        ) : (
          <Layout>
            <Header className='header'>
              <AppHeader />
            </Header>
            <Content style={{ backgroundColor: '#fff' }}>
              <Routes>
                <Route path="/" element={<SelectDate />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/profile" element={<PrivateRoute> <Profile /> </PrivateRoute>} />
                <Route path="/bookings" element={<PrivateRoute> <Bookings /> </PrivateRoute>} />
                <Route path="/bookings/:id" element={<PrivateRoute> <BookingDetails /> </PrivateRoute>} />

                <Route path="/hotels" element={<PrivateRoute> <SelectHotel /> </PrivateRoute>} />
                <Route path="/personalization" element={<PrivateRoute> <SelectPersonolization /> </PrivateRoute>} />
                <Route path="/confirmation" element={<PrivateRoute> <Confirmation /> </PrivateRoute>} />

                <Route path="/singleHotel/search" element={<SelectDateSingle />} />
                <Route path="/singleHotel/hotels" element={<SelectHotelSingle />} />
                <Route path="/withoutTab/personalization" element={<SelectPersonolizationWithoutTab />} />

              </Routes>
            </Content>
            <Footer className='footer'>
              <AppFooter />
            </Footer>
          </Layout>
        )}
      </Router>
    </div>
  );
}

export default App;
