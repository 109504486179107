import React, { useEffect, useState } from "react";
import Step from "../Components/Step";
import Tab from "../Components/Tab";
import { Col, Row } from "antd";
import Cart from "../Components/Cart";
import TabWithoutTab from "../singleHotel/TabWithoutTab";
import axios from "axios";

const SelectPersonolization = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [switchTab, setSwitchTab] = useState(false);

  const fetchSwitchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/personolizaion/getSwitchTabs.php`, {
        params: { hotelId: "123" },
      });
      if (response.data.status === "success") {
        if (response.data.switchtab === 1) {
          setSwitchTab(true);
        } else {
          setSwitchTab(false);
        }
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    fetchSwitchData();
  }, []);

  return (
    <div style={{ paddingBottom: "50px" }}>
      <div className="container">
        <Step active={3} />

        <Row>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            {switchTab ? <Tab /> : <TabWithoutTab />}
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Cart />
          </Col>
        </Row>
      </div>
        
    </div>
  );
};

export default SelectPersonolization;
