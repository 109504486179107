import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Tag,
  TimePicker,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const { RangePicker } = DatePicker;

const BookingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [editModelOpen, setEditModelOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]); // [checkIn, checkOut]
  const [editLoading, setEditLoading] = useState(false);

  const getBookings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/getSingleBooking.php`, {
        params: { id },
      });
      setBookings(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching Hotels:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBookings();
  }, []);

  const getCheckInStatusText = (status, checkInDate) => {
    if (status === "pending" && checkInDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const checkIn = new Date(checkInDate);
      checkIn.setHours(0, 0, 0, 0);

      const timeDiff = checkIn - today;
      const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (daysLeft > 1) return `${daysLeft} days left`;
      if (daysLeft === 1) return "1 day left";
      if (daysLeft === 0) return "Today";
      return "No checkIn";
    }

    return status;
  };

  const getCheckInStatusColor = (status, checkInDate) => {
    if (status === "pending" && checkInDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const checkIn = new Date(checkInDate);
      checkIn.setHours(0, 0, 0, 0);

      const daysLeft = Math.ceil((checkIn - today) / (1000 * 60 * 60 * 24));

      if (daysLeft >= 1) return "blue";
      if (daysLeft === 0) return "green";
      return "red";
    }

    switch (status) {
      case "checkin":
        return "green";
      case "checkout":
        return "red";
      default:
        return "default";
    }
  };

  const handleCancel = (id) => {
    showCancelConfirm(id);
  };

  const canceleBooking = async (id) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/cancel_booking.php`, {
        id,
      });
      message.success(response.data.message);
      getBookings();
    } catch (error) {
      message.error("Failed to Cancel Booking");
    }
  };

  const showCancelConfirm = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Cancel Booking?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => canceleBooking(id),
    });
  };

  // Edit Booking
  const [form] = Form.useForm();

  const isOpenEditModal = () => {
    setEditModelOpen(true);

    form.setFieldsValue({
      date: [
        moment(bookings.checkIn, "YYYY-MM-DD"),
        moment(bookings.checkOut, "YYYY-MM-DD"),
      ],
    });
  };
  const isCloseEditModal = () => {
    setEditModelOpen(false);
    form.resetFields();
    setSelectedDates([]);
  };

  const onFinish = async (values) => {
    setEditLoading(true);

    if (!values?.date?.[0] || !values?.date?.[1]) {
      message.error("Please select check-in and check-out dates.");
      return;
    }

    if (!bookings?.id) {
      message.error("Booking ID is missing.");
      return;
    }

    const checkIn = values.date[0].format("YYYY-MM-DD");
    const checkOut = values.date[1].format("YYYY-MM-DD");
    const nights = values.date[1].diff(values.date[0], "days") || 0;

    const taxes = bookings?.allPrice?.taxes || 0;
    const discount = bookings?.allPrice?.discount || 0;
    const itemTotal = bookings?.allPrice?.itemTotal || 0;
    const laterPayment = bookings?.allPrice?.laterPayment || 0;
    const roomPricePerNight = bookings?.roomType?.price || 0;
    const promoDiscount = bookings?.promo?.price || 0;

    const roomTotal = roomPricePerNight * nights;
    const subTotal = roomTotal + itemTotal;
    const Total = Math.max(subTotal + taxes - discount - promoDiscount, 0);

    const allPrice = {
      taxes,
      subTotal,
      roomTotal,
      laterPayment,
      itemTotal,
      discount,
      Total,
    };

    const updatedItems =
      bookings?.items?.map((item, index) => ({
        ...item,
        date: values[`date_${index}`]?.format("YYYY-MM-DD") || item.date,
        time: values[`time_${index}`]?.format("HH:mm:ss") || item.time,
      })) || [];

    let availabelRoom = null;

    try {
      const response = await axios.get(
        `${API_BASE_URL}/RAS/availibilityWithCheckInCheckOutDate.php`,
        {
          params: { checkIn, checkOut },
        }
      );

      const filteredRooms = response?.data?.available_rooms?.filter(
        (room) =>
          room.hotel_Id === bookings?.hotel?.id &&
          room.roomType_Id === bookings?.roomType?.id
      );

      if (!filteredRooms || filteredRooms.length === 0) {
        message.error("This Data Room Not Available");
        return;
      }

      availabelRoom = filteredRooms[0];
    } catch (error) {
      console.error(
        "Error fetching available rooms:",
        error.response?.data || error.message
      );
    }

    console.log(bookings?.id);
    const formData = new FormData();
    formData.append("id", bookings?.id);
    formData.append("fName", bookings?.fName);
    formData.append("lName", bookings?.lName);
    formData.append("email", bookings?.email);
    formData.append("mobileNumber", bookings?.mobileNumber);
    formData.append("guestAddress", JSON.stringify(bookings?.guestAddress));
    formData.append("roomrequest", JSON.stringify(bookings?.roomrequest));
    formData.append("other_request", bookings?.other_request);
    formData.append("extraFries", bookings?.extraFries);
    formData.append("acceptTermCondition", bookings?.acceptTermCondition);
    formData.append("totalPrice", Total);
    formData.append("checkIn", checkIn);
    formData.append("checkOut", checkOut);
    formData.append("nights", nights);
    formData.append("asign_roomId", availabelRoom?.id);
    formData.append("asign_roomNumber", availabelRoom?.room_number);
    formData.append("destination", JSON.stringify(bookings?.destination));
    formData.append("guest", JSON.stringify(bookings?.guest));
    formData.append("hotel", JSON.stringify(bookings?.hotel));
    formData.append("items", JSON.stringify(updatedItems));
    formData.append("promo", JSON.stringify(bookings?.promo));
    formData.append("roomType", JSON.stringify(bookings?.roomType));
    formData.append("allPrice", JSON.stringify(allPrice));
    formData.append("paymentData", JSON.stringify(bookings?.paymentData));

    try {
      const response = await axios.post(
        `${API_BASE_URL}/edit_booking.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        message.success(response.data.message);
        getBookings();
        setEditModelOpen(false);
        form.resetFields();
        setSelectedDates([]);
      } else {
        message.error("Booking Not Change");
      }
    } catch (error) {
      message.error("Failed to submit data");
      console.error("Error:", error);
    } finally {
      setEditLoading(false);
    }
  };

  const isDisabled = (current) => {
    if (!current) return false;
    return current.isBefore(moment().startOf("day"));
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    form.setFieldsValue({ date: dates });
  };

  const disabledItemDate = (current) => {
    if (!selectedDates || selectedDates.length < 2) {
      return true;
    }
    const [checkIn, checkOut] = selectedDates;
    return current && (current < checkIn || current > checkOut);
  };

  return (
    <div>
      <div className="container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
              style={{ marginRight: "10px" }}
            />
            <h2 style={{ padding: "20px 0" }}>Booking Details</h2>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={isOpenEditModal}
              type="primary"
              className="btn-find-hotel"
              disabled={
                moment(bookings?.checkIn).diff(moment(), "days") < 2 ||
                ["cancelled", "rejected"].includes(bookings?.bookingStatus)
              }
            >
              Edit
            </Button>
            <Button
              disabled={
                moment(bookings?.checkIn).diff(moment(), "days") < 2 ||
                ["cancelled", "rejected"].includes(bookings?.bookingStatus)
              }
              onClick={() => handleCancel(bookings?.id)}
              danger
            >
              Cancel Booking
            </Button>
          </div>
        </div>

        <Modal
          open={editModelOpen}
          title={"Modify Booking"}
          onCancel={isCloseEditModal}
          footer={false}
        >
          <Form
            form={form}
            name="Modify Booking Form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Select CheckIn & Checkout Date"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please select date",
                    },
                  ]}
                >
                  <RangePicker
                    onChange={handleDateChange}
                    disabledDate={isDisabled}
                  />
                </Form.Item>
              </Col>

              {bookings?.items?.map((item, index) => (
                <Col span={24} key={index} style={{ display: "flex" }}>
                  <Form.Item
                    label={`Date for ${item.title}`}
                    name={`date_${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Please select date",
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={!selectedDates?.length}
                      disabledDate={disabledItemDate}
                    />
                  </Form.Item>
                  <Form.Item
                    label={`Time for ${item.title}`}
                    name={`time_${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Please select Time",
                      },
                    ]}
                  >
                    <TimePicker
                      format="HH:mm:ss"
                      disabled={!selectedDates?.length}
                    />
                  </Form.Item>
                </Col>
              ))}

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item wrapperCol={{}}>
                  <Button
                    className="btn-find-hotel"
                    type="primary"
                    htmlType="submit"
                    loading={editLoading}
                  >
                    Modify Booking
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        {loading === true ? (
          <p style={{ padding: "20px", textAlign: "center" }}>Loading...</p>
        ) : (
          <div className="details" style={{ overflow: "auto" }}>
            <Card
              title={`Confirmation Numbar : ${id}`}
              headStyle={{
                textAlign: "right",
                fontWeight: "bold",
                backgroundColor: "#fafafa",
              }}
              style={{ minWidth: "900px" }}
            >
              <Row style={{ padding: "20px" }}>
                <Col span={12}>
                  <div className="hotelinfo">
                    <h3>{bookings?.hotel?.hotelname}</h3>
                    <p>{bookings?.hotel?.email}</p>
                    <p>{bookings?.hotel?.address}</p>
                    <p>
                      {bookings?.hotel?.city}, {bookings?.hotel?.country},{" "}
                      {bookings?.hotel?.zipCode}
                    </p>
                  </div>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <p>{bookings?.created_at}</p>
                  <b>Room Numbar : {bookings?.asign_roomNumber}</b>
                </Col>
              </Row>

              <Row style={{ padding: "20px" }}>
                <Col span={12}>
                  <div className="hotelinfo">
                    <b>CheckIn : {bookings?.checkIn}</b>
                  </div>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <b>CheckOut : {bookings?.checkOut}</b>
                </Col>
              </Row>

              <Row style={{ padding: "0 20px 20px" }}>
                <Col span={24}>
                  <div className="hotelinfo">
                    <table
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderCollapse: "collapse",
                      }}
                      cellPadding={10}
                      border={1}
                    >
                      <tr style={{ background: "#f5f5f5" }}>
                        <th style={{ padding: "12px 16px" }}>Room ID</th>
                        <th style={{ padding: "12px 16px" }}>Room Name</th>
                        <th style={{ padding: "12px 16px" }}>Room Catagory</th>
                        <th style={{ padding: "12px 16px" }}>Num. Of Guest</th>
                        <th style={{ padding: "12px 16px" }}>
                          Selected Nights
                        </th>
                        <th style={{ padding: "12px 16px" }}>
                          Room Price (1 night)
                        </th>
                        <th style={{ padding: "12px 16px" }}>Total</th>
                      </tr>
                      <tr>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.roomType?.id}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.roomType?.roomName}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.roomType?.catagory}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.guest?.adults}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.nights}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.roomType?.price}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.allPrice?.roomTotal}
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>

              <Row style={{ padding: "0 20px 20px" }}>
                <Col span={24}>
                  <div className="hotelinfo">
                    <h3>SELECTED AMENITIES</h3>
                    <table
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderCollapse: "collapse",
                      }}
                      cellPadding={10}
                      border={1}
                    >
                      <tr style={{ background: "#f5f5f5" }}>
                        <th style={{ padding: "12px 16px" }}>ID</th>
                        <th style={{ padding: "12px 16px" }}>Name</th>
                        <th style={{ padding: "12px 16px" }}>Type</th>
                        <th style={{ padding: "12px 16px" }}>occesion</th>
                        <th style={{ padding: "12px 16px" }}>special</th>
                        <th style={{ padding: "12px 16px" }}>Date</th>
                        <th style={{ padding: "12px 16px" }}>Time</th>
                        <th style={{ padding: "12px 16px" }}>Payment</th>
                        <th style={{ padding: "12px 16px" }}>Price</th>
                      </tr>
                      {bookings?.items?.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: "12px 16px" }}>{item?.id}</td>
                          <td style={{ padding: "12px 16px" }}>
                            {item?.title}
                          </td>
                          <td style={{ padding: "12px 16px" }}>{item?.type}</td>
                          <td style={{ padding: "12px 16px" }}>
                            {item?.occesion} (${item?.occesionPrice})
                          </td>
                          <td style={{ padding: "12px 16px" }}>
                            {item?.special
                              ? `${item?.special} ($${item?.specialPrice})`
                              : "-"}
                          </td>
                          <td style={{ padding: "12px 16px" }}>{item?.date}</td>
                          <td style={{ padding: "12px 16px" }}>{item?.time}</td>
                          <td style={{ padding: "12px 16px" }}>
                            {item?.paymentType}
                          </td>
                          <td style={{ padding: "12px 16px" }}>
                            {item?.paymentType === "Later"
                              ? `${item?.laterPrice}`
                              : `${item?.price}`}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Col>
              </Row>

              <Row style={{ padding: "20px" }}>
                <Col span={16}> </Col>
                <Col span={4}>
                  <p>subTotal</p>
                  <p>Taxes</p>
                  <p>Discount</p>
                  <p>Promocode Discount</p>
                  <b>Total</b>
                  <p>Pay Later</p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </Col>
                <Col span={2} style={{ textAlign: "right" }}>
                  <p>${bookings?.allPrice?.subTotal}</p>
                  <p>${bookings?.allPrice?.taxes}</p>
                  <p>${bookings?.allPrice?.discount}</p>
                  <p>${bookings?.promo?.price}</p>
                  <b>${bookings?.allPrice?.Total}</b>
                  <p>${bookings?.allPrice?.laterPayment}</p>
                </Col>
                <Col span={1}> </Col>
              </Row>

              <hr />
              <Row style={{ padding: "20px" }}>
                <Col span={12}>
                  <h3>Guest Information :</h3>
                  <p>
                    {bookings?.fName} {bookings?.lName}
                  </p>
                  <p>{bookings?.email}</p>
                  <p>{bookings?.mobileNumber}</p>
                  <p>{bookings?.guestAddress?.address1}</p>
                  <p>
                    {bookings?.guestAddress?.address2},{" "}
                    {bookings?.guestAddress?.city},{" "}
                    {bookings?.guestAddress?.state},{" "}
                    {bookings?.guestAddress?.country},{" "}
                    {bookings?.guestAddress?.zipcode}
                  </p>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}></Col>
              </Row>

              <Row style={{ padding: "20px" }}>
                <Col span={24}>
                  <h3>Room Request : </h3>
                  <ul>
                    {bookings?.roomrequest?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Col>
              </Row>

              <Row style={{ padding: "20px" }}>
                <Col span={24}>
                  <h3>Other Request : </h3>
                  <p>{bookings?.other_request}</p>
                  <p>
                    Want Extra Fries with that ? <b>{bookings?.extraFries}</b>
                  </p>
                  <p>
                    Accept Term Condition ?
                    <b> {bookings?.acceptTermCondition}</b>
                  </p>
                </Col>
              </Row>

              <Row style={{ padding: "0 20px 20px" }}>
                <Col span={24}>
                  <div className="hotelinfo">
                    <table
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderCollapse: "collapse",
                      }}
                      cellPadding={10}
                      border={1}
                    >
                      <tr style={{ background: "#f5f5f5" }}>
                        <th style={{ padding: "12px 16px" }}>
                          Card Holder Name
                        </th>
                        <th style={{ padding: "12px 16px" }}>Card Number</th>
                        <th style={{ padding: "12px 16px" }}>
                          Expiration Date
                        </th>
                        <th style={{ padding: "12px 16px" }}>CVV Number</th>
                        <th style={{ padding: "12px 16px" }}>Billing Zip</th>
                      </tr>
                      <tr>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.paymentData?.creditCardName}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.paymentData?.creditCardNumber}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.paymentData?.expirationMonth}/
                          {bookings?.paymentData?.expirationYear}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.paymentData?.CVVNumber}
                        </td>
                        <td style={{ padding: "12px 16px" }}>
                          {bookings?.paymentData?.BillingZipCode}
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>

              <Row style={{ padding: "0 20px 20px" }}>
                <Col span={24}>
                  <div className="hotelinfo">
                    <h3>STATUS : </h3>
                    <table
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderCollapse: "collapse",
                      }}
                      cellPadding={10}
                      border={1}
                    >
                      <tr style={{ background: "#f5f5f5" }}>
                        <th style={{ padding: "12px 16px" }}>Booking Status</th>
                        <th style={{ padding: "12px 16px" }}>CheckIn Status</th>
                        <th style={{ padding: "12px 16px" }}>Payment Status</th>
                        <th style={{ padding: "12px 16px" }}>Total Due</th>
                      </tr>
                      <tr>
                        <td style={{ padding: "12px 16px" }}>
                          <Tag
                            color={
                              (bookings?.bookingStatus === "pending" &&
                                "yellow") ||
                              (bookings?.bookingStatus === "confirmed" &&
                                "green") ||
                              (bookings?.bookingStatus === "cancelled" &&
                                "red") ||
                              (bookings?.bookingStatus === "rejected" && "red")
                            }
                          >
                            {bookings?.bookingStatus}
                          </Tag>{" "}
                        </td>

                        <td style={{ padding: "12px 16px" }}>
                          <Tag
                            color={getCheckInStatusColor(
                              bookings?.checkInStatus,
                              bookings?.checkIn
                            )}
                          >
                            {getCheckInStatusText(
                              bookings?.checkInStatus,
                              bookings?.checkIn
                            )}
                          </Tag>
                        </td>

                        <td style={{ padding: "12px 16px" }}>
                          <Tag
                            color={
                              (bookings?.paymentStatus === "pending" &&
                                "yellow") ||
                              (bookings?.paymentStatus === "paid" && "green") ||
                              (bookings?.paymentStatus === "failed" && "red")
                            }
                          >
                            {bookings?.paymentStatus}
                          </Tag>
                        </td>

                        <td style={{ padding: "12px 16px" }}>
                          $
                          {bookings?.allPrice?.Total +
                            bookings?.allPrice?.laterPayment}
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingDetails;
