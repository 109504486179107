import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import authSlice from './authSlice';
import searchHotelSlice from './searchHotelSlice';
import cartSlice from './cartSlice';
import hotelSlice from './hotelSlice';
import mobileSlice from './mobileSlice';

const authPersistConfig = {
    key: 'auth',
    storage,
};
const searchPersistConfig = {
    key: 'searchHotel',
    storage,
};
const cartPersistConfig = {
    key: 'searchHotel',
    storage,
};
const hotelPersistConfig = {
    key: 'hotel',
    storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);
const persistedSearchReducer = persistReducer(searchPersistConfig, searchHotelSlice);
const persistedCartReducer = persistReducer(cartPersistConfig, cartSlice);
const persistedHotelReducer = persistReducer(hotelPersistConfig, hotelSlice);

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        searchHotel: persistedSearchReducer,
        cart: persistedCartReducer,
        hotel: persistedHotelReducer,
        mobile: mobileSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
            },
        }),
});

const persistor = persistStore(store);

export { store, persistor };
