import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { addDestination } from "../redux/cartSlice";

const { Option } = Select;

const Destination = () => {
  const [loading, setLoading] = useState(false);
  const [destinationList, setDestinationList] = useState([]);

  const dispatch = useDispatch();
  const destination = useSelector((state) => state?.cart?.destination);
  const hotels = useSelector((state) => state?.hotel?.hotels);

  const handleDestinationChange = (value) => {
    const [city, country] = value.split(", ").map((item) => item.trim());
    dispatch(addDestination({ city, country }));
  };


  useEffect(() => {
    if (hotels?.length > 0) {
      setDestinationList((prevList) => {
        const uniqueEntries = new Map(
          prevList.map((item) => [item.city.trim() + item.country.trim(), item])
        );

        hotels.forEach((item) => {
          const key = item.city.trim() + item.country.trim();
          if (!uniqueEntries.has(key)) {
            uniqueEntries.set(key, {
              city: item.city.trim(),
              country: item.country.trim(),
            });
          }
        });

        return Array.from(uniqueEntries.values());
      });
    }
  }, [hotels]);

  return (
    <div className="form-group">
      <label htmlFor="">
        <FontAwesomeIcon className="icon" icon={faLocationDot} /> Destination
      </label>
      <Select
        showSearch
        placeholder="Where Can We Take You"
        style={{ width: 200 }}
        value={`${destination.city}, ${destination.country}` || undefined}
        onChange={handleDestinationChange}
      >
        {destinationList.map((hotel, index) => (
          <Option
            loading={loading}
            key={index}
            value={`${hotel.city}, ${hotel.country}`}
          >
            {`${hotel.city}, ${hotel.country}`}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Destination;
