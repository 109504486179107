import React, { useEffect, useState } from "react";
import { Button, Dropdown, InputNumber, Row, Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setRoomAndGuest } from "../redux/searchHotelSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import { addGuest } from "../redux/cartSlice";

const RoomAndGuest = () => {
  const dispatch = useDispatch();
  const roomAndGuest = useSelector((state) => state.cart.guest);
  const hotelType = useSelector((state) => state?.hotel?.hotelType);
  const [open, setOpen] = useState(false);
  const [maxAdult, setMaxAdult] = useState();


  useEffect(() => {
    const maxaduls = hotelType.map((type) =>
      parseFloat(type.sleeps)
    );
    const max = Math.max(...maxaduls).toFixed(0);
    setMaxAdult(max)
  },[hotelType])

  const handleRoomChange = (rooms) =>
    dispatch(addGuest({ ...roomAndGuest, rooms }));
  const handleAdultChange = (adults) =>
    dispatch(addGuest({ ...roomAndGuest, adults }));
  const handleChildrenChange = (children) =>
    dispatch(addGuest({ ...roomAndGuest, children }));

  const menuItems = [
    {
      key: "1",
      label: (
        <Row>
          <Col span={8}>Rooms</Col>
          <Col span={16}>
            <InputNumber
              min={1}
              max={1}
              value={roomAndGuest.rooms}
              onChange={handleRoomChange}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: (
        <Row>
          <Col span={8}>Adults</Col>
          <Col span={16}>
            <InputNumber
              min={1}
              max={maxAdult}
              value={roomAndGuest.adults}
              onChange={handleAdultChange}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: (
        <Row>
          <Col span={8}>Children</Col>
          <Col span={16}>
            <InputNumber
              min={0}
              max={3}
              value={roomAndGuest.children}
              onChange={handleChildrenChange}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="form-group">
      <label htmlFor="">
        <FontAwesomeIcon className="icon" icon={faUserTag} />
        Rooms & Guests
      </label>
      <Dropdown menu={{ items: menuItems }} open={open} onOpenChange={setOpen}>
        <Button>
          {roomAndGuest.rooms} Room, {roomAndGuest.adults} Adult
          {roomAndGuest.adults > 1 ? "s" : ""} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default RoomAndGuest;
